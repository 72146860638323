module.exports = {
  ondcCancelReason: {
    "001":
      "Price of one or more items have changed due to which buyer was asked to make additional payment",
    "002": "One or more items in the Order not available",
    "003": "Product available at lower than order price",
    "004":
      "Order / fulfillment in pending shipment / delivery state for too long",
    "005": "Merchant rejected the order",
    "006": "Order / fulfillment not received as per buyer app SLA",
    "008": "Order / fulfillment not ready for pickup",
    "009": "Wrong product delivered",
    "010": "Buyer wants to modify details",
    "011": "Buyer not found or cannot be contacted",
    "012": "Buyer does not want product any more",
    "013": "Buyer refused to accept delivery",
    "014": "Address not found",
    "015": "Buyer not available at location",
    "016": "Accident / rain / strike / vehicle issues",
    "017": "Order delivery delayed or not possible",
    "018": "Delivery pin code not serviceable",
    "019": "Pickup pin code not serviceable",
    "020": "Order lost in transit",
    "021": "Packed order not complete",
  },
  buyerReason: {
    "001": "Buyer does not want product any more",
    "002": "Product available at lower than order price",
    "003": "Product damaged or not in usable state",
    "004": "Product is of incorrect quantity or size",
    "005": "Product delivered is different from what was shown and ordered",
  },
  selfDeliveryIssue: {
    "001": "Address not found",
    "002": "Buyer not found or cannot be contacted",
    "003": "Buyer does not want product any more",
    "004": "Buyer refused to accept delivery",
    "005": "Buyer not available at location",
  },
  // selfDeliveryIssue:{
  //   "11":	"Buyer not found or cannot be contacted",
  //   "12":	"Buyer does not want product any more",
  //   "13":	"Buyer refused to accept delivery",
  //   "14":	"Address not found",
  //   "15":	"Buyer not available at location"
  // },
  returnRejectCode: {
    "001": "Buyer does not want product any more",
    "002": "Product available at lower than order price",
    "003": "Product damaged or not in usable state",
    "004": "Product is of incorrect quantity or size",
    "005": "Product delivered is different from what was shown and ordered"
  },
  issueSubcategory: {
    "ORD01": "Order not received",
    "ORD02": "Quality issue",
    "ORD03": "Delayed delivery",
    "ORD05": "Store not responsive",
    "ORD04": "Invoice missing",
    "ITM01": "Missing items",
    "ITM02": "Quantity issue",
    "ITM03": "Item mismatch",
    "ITM04": "Quality issue",
    "ITM05": "Expired item",
    "ITM06": "Incorrectly marked as returned",
    "FLM01": "Wrong delivery address",
    "FLM02": "Delay in delivery",
    "FLM03": "Delayed delivery",
    "FLM04": "Packaging",
    "FLM05": "Buyer not found",
    "FLM06": "Seller not found",
    "FLM07": "Package info mismatch",
    "FLM08": "Incorrectly marked as delivered",
    "AGT01": "Agent behavioral issue",
    "AGT02": "Buyer behavioral issue",
    "PMT01": "Refund not received",
    "PMT02": "Underpaid",
    "PMT03": "Overpaid",
    "PMT04": "Not paid",
}
};
